import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Logo from "./logo.svg";
import srb from "./serbia.svg";
import uk from "./uk.svg";
import "./Navigation.css";
import { LinkContainer } from "react-router-bootstrap";
import { withTranslation } from "react-i18next";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transparent: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    //let t = document.getElementById('logo-centered');

    /* t.addEventListener('transitionend', () => {
      if (t.classList.contains('opacity-0')) {
        t.classList.remove('d-block');
        t.classList.add('d-none');
      }
    });*/
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  setTransparent = (res) => {
    this.setState((state) => ({
      transparent: res,
    }));
  };

  handleScroll = () => {
    const st = window.scrollY;

    if (st > 100 && !this.state.transparent) {
      this.setTransparent(true);
    }
    if (st < 100 && this.state.transparent) {
      this.setTransparent(false);
    }
  };

  handleLang = () => {
    const { i18n } = this.props;
    if (i18n.language === "en") i18n.changeLanguage("rs");
    else i18n.changeLanguage("en");
    window.location.reload();
  };

  handleClick = (e) => {
    /*let z = document.getElementsByClassName('custom-nav')[0];
    if (!z.classList.contains('removeTransparency'))
      z.classList.add('removeTransparency');
    else z.classList.remove('removeTransparency');

     let t = document.getElementById('logo-centered');

    if (t.classList.contains('opacity-0')) {
      t.style.opacity = 1;
      t.classList.remove('opacity-0');
      t.classList.add('opacity-1');
      t.classList.add('d-block');
      t.classList.remove('d-none');
    } else {
      t.style.opacity = 0;
      t.classList.remove('opacity-1');
      t.classList.add('opacity-0');
    }*/
  };

  render() {
    const { i18n, t } = this.props;
    return (
      <div>
        <Navbar
          fixed="top"
          collapseOnSelect
          expand="lg"
          bg="blue1"
          variant="blue1"
          className={`custom-nav navbar-resized p-0 justify-content-start ${
            this.state.transparent ? "navbar-transparent" : "navbar-original"
          }`}
        >
          <LinkContainer
            exact={true}
            to="/"
            className="d-lg-none flex-grow-1 w-100"
            id="logo-centered"
          >
            <Navbar.Brand className="p-0">
              <img
                alt=""
                src={Logo}
                height="80"
                className="d-inline-block align-top"
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="position-absolute"
          />
          <Navbar.Collapse id="responsive-navbar-nav">
            <div className="container">
              <Nav className="row w-100">
                <div className="col-4 d-flex align-self-center justify-content-around flex-column flex-lg-row">
                  <LinkContainer exact={true} to="/">
                    <Nav.Link>{t("Nav.1")}</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/onama">
                    <Nav.Link>{t("Nav.2")}</Nav.Link>
                  </LinkContainer>
                </div>
                <div className="col-4 align-self-center justify-content-around d-none d-lg-flex">
                  <LinkContainer exact={true} to="/">
                    <Navbar.Brand className="p-0">
                      <img
                        alt=""
                        src={Logo}
                        height="80"
                        className="d-inline-block align-top"
                      />
                    </Navbar.Brand>
                  </LinkContainer>
                </div>
                <div className="col-4 d-flex align-self-center justify-content-around flex-column flex-lg-row">
                  <LinkContainer to="/kategorije">
                    <Nav.Link>{t("Nav.3")}</Nav.Link>
                  </LinkContainer>
                  <LinkContainer to="/kontakt">
                    <Nav.Link>{t("Nav.4")}</Nav.Link>
                  </LinkContainer>
                  <Nav.Link
                    onClick={this.handleLang}
                    className="d-block d-lg-none"
                  >
                    {i18n.language === "en" ? (
                      <img
                        alt=""
                        src={srb}
                        height="20"
                        className="ml-2 d-inline-block"
                      />
                    ) : (
                      <img
                        alt=""
                        src={uk}
                        height="20"
                        className="ml-2 d-inline-block"
                      />
                    )}
                  </Nav.Link>
                </div>
              </Nav>
            </div>
            <Nav.Link
              onClick={this.handleLang}
              className="float-righter d-none d-lg-block opacity-1 "
            >
              {i18n.language === "en" ? (
                <img
                  alt=""
                  src={srb}
                  height="20"
                  className="ml-2 d-inline-block"
                />
              ) : (
                <img
                  alt=""
                  src={uk}
                  height="20"
                  className="ml-2 d-inline-block"
                />
              )}
            </Nav.Link>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

export default withTranslation()(Navigation);

//<div className={`${this.state.resized ? 'm-80' : 'm-160'}`}></div>
